import {dataPostRequest, dataPutRequest, deleteRequest, getRequest, paramsPostRequest} from "@/utils/request";
/**
 * 获取当前登陆用户信息
 * @returns {AxiosPromise}
 * @constructor
 */
export const getUserInfo = () => {
    return getRequest('/getUserInfo');
};

export const getConsumerInfo = () => {
    return getRequest('/getUserInfo');
};




/**
 * 条件查询信息
 * @param param
 */
export const listConsumerByCondition = (param) => {
    return getRequest('/consumer/listConsumerByCondition', param);
};

export const listConsumerPage = (param) => {
    return getRequest('/consumer/listConsumerPage', param);
};



export const saveConsumer = (data) => {
    return dataPostRequest('/consumer/saveConsumer', data);
};


export const updateConsumer = (data) => {
    return dataPutRequest('/consumer/updateConsumer', data);
}


export const deleteConsumerById = (id) => {
    return deleteRequest('/consumer/deleteConsumer/' + id);
};


export const updateConsumerPwd = (data) => {
    return dataPutRequest('/consumer/updateConsumerPwd/', data);
};
export const verifyConsumer = (id, data) => {
    return dataPutRequest('/consumer/verifyConsumer/' + id, data);
};
