import {messageInfo} from "@/utils/MessageInfo";
import axios from "axios";
import {saveAs} from "file-saver";

//=======================  请求拦截器  ===========================
axios.interceptors.request.use(
    config => {
        if (window.sessionStorage.getItem('token')) {
            config.headers['Authorization'] = window.sessionStorage.getItem('token');
        }
        return config;
    }, error => {
        console.log("Something Wrong With The Request ==>  ", error);
    }
)

//=======================  相应拦截器  ===========================
axios.interceptors.response.use(
    res => {
        if (res.status && res.status === 200) {
            //  业务逻辑
            if (res.data.code === 50000 || res.data.code === 401 || res.data.code === 40003) {
                messageInfo({type: 'error', message: res.data.message});
                return res.status = false;
            } else {
                return res.data;
            }
        }
        messageInfo({type: "error", message: "连接错误！"});

    }, error => {
        if (error.response.code === 404 || error.response.code === 504) {
            messageInfo({type: "error", message: "服务器被怪兽吃了  ┴┴︵╰（‵□′）╯︵┴┴ "});
        } else if (error.response.code === 403) {
            messageInfo({type: "error", message: "权限不足，请联系管理员！"});
        } else if (error.response.code === 401) {
            messageInfo({type: "error", message: "尚未登陆，请登陆！"});
            this.$router.replace('/');
        } else {
            if (error.response.message) {
                messageInfo({type: "error", message: error.response.message});
            } else {
                messageInfo({type: "error", message: "网络异常！"});
            }
        }

    }
);

//=======================  请求封装  ===========================
let baseUrl = "/v2/api";
let downloadUrl = "/v2/api/file/download";

export  const  getBaseUrl = () => {
    return `${baseUrl}`;
}

export  const  getDownloadUrl = () => {
    return `${downloadUrl}`;
}

// Get
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${baseUrl}${url}`,
        params: params
    })
}

// Post
export const dataPostRequest = (url, data) => {
    return axios({
        method: 'post',
        url: `${baseUrl}${url}`,
        data: data
    })
}

//Post
export const paramsPostRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${baseUrl}${url}`,
        params: params
    })
};

// Put
export const paramsPutRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${baseUrl}${url}`,
        params: params
    })
}

// Put
export const dataPutRequest = (url, data) => {
    return axios({
        method: 'put',
        url: `${baseUrl}${url}`,
        data: data
    });
};

// delete
export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${baseUrl}${url}`,
        params: params
    })
}

// delete
export const scanfPdfRequest = (url, fileName) => {
// 拼接完整的URL
    var url = `${baseUrl}${url}`
console.log(111)
// 使用axios发送POST请求
    axios({
        method: 'get',
        url: url,
        responseType: 'blob', // 将响应类型设置为'blob',
        headers: { 'Authorization': 'Bearer ' + window.sessionStorage.getItem("token") }
    }).then(response => {
        // 创建一个新的Blob对象来表示响应数据
        const blob = new Blob([response.data]);
        // 创建一个临时URL来表示这个Blob对象
        const url = URL.createObjectURL(blob);
        console.log(url)
        // 新增代码：在新窗口中预览PDF文件
        const previewWindow = window.open(url);
        // 新增代码：在新窗口中添加一个下载按钮
        previewWindow.onload = () => {
            const downloadButton = previewWindow.document.createElement("button");
            downloadButton.innerText = "Download";
            // 当用户点击下载按钮时，创建一个<a>元素并设置其href属性为临时URL
            downloadButton.onclick = () => {
                const link = document.createElement("a");
                link.href = url;
                // 设置download属性以便在用户点击链接时下载文件并将其保存为'zy.pdf'
                link.download = fileName+'.pdf';
                // 将链接添加到文档中
                document.body.appendChild(link);
                // 模拟点击链接以下载PDF文件
                link.click();
            }
            // 将下载按钮添加到新窗口中
            previewWindow.document.body.appendChild(downloadButton);
        }
    });
}

// delete
export const scanfPdfRequest2 = async (url, fileName) => {
    // 拼接完整的URL
    var url = `${baseUrl}${url}`
    console.log(222)
    /* fetch(url)
         .then(response => response.blob())
         .then(blob => {
             const url = window.URL.createObjectURL(blob);
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', fileName);
             document.body.appendChild(link);
             link.click();
         })
         .catch(error => {
             //console.error(error);
         });*/

    const response = await fetch(url);
    const reader = response.body.getReader();
    //response.body.getReader() 返回一个遍历器，这个遍历器 read() 方法每次
    //都会返回一个对象，表示本次读取的内容块。
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }
        console.log(`Received ${value.length} bytes`)
        console.log('转码后内容如下：',new TextDecoder('utf-8').decode(value))

        /*const a = document.createElement('a');
        a.href = objectUrl;
        a.download = 'downloaded-file';
        document.body.appendChild(a);
        a.click();*/
    }
}

// delete
export const downPdfRequestByAxios = (url, fName) => {
    const fileUrl = `${baseUrl}${url}`; // 文件的URL地址
    const fileName = `${fName}.ext`; // 文件的URL地址
    axios({
        method: 'get',
        url: fileUrl,
        responseType: 'blob',
        //fileName: fileName // 后端设置的文件名
    })
        .then(response => {
            const blob = new Blob([response.data]);
            const url = URL.createObjectURL(blob); // 需要HTTPS.
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName; // 后端设置的文件名
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch(error => console.error(error));

}

// delete
export const downPdfRequest = (url, fileName) => {
    const fileUrl = `${baseUrl}${url}`; // 文件的URL地址
    const link = document.createElement('a');
    link.href = fileUrl;
    //link.setAttribute('download', fileName);
    link.click();
}